import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ContratMenuConviveSupplier} from '../contratmenu-convive-resolver.service';
import {DIALOG, FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, START_DELETEQUESTION} from '../../../core/constants';
import {UtilsService} from '../../../core/utils/utils.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../../core/suppliers/form-fieldbase-supplier';
import {ContratsMenusConvivesService} from '../../../core/services/gestioncontrats/contrats-menus-convives.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {DialogDeleteSupplier} from '../../../core/suppliers/dialog-delete-supplier';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";
import {ContrainteModel} from "../../../core/models/contrainte-model";
import {DeclinaisonDTO} from "../../../core/dtos/declinaison-dto";

@Component({
  selector: 'yo-cmc-ficheidentite',
  templateUrl: './cmc-ficheidentite.component.html',
  styleUrls: ['./cmc-ficheidentite.component.scss']
})
export class CmcFicheidentiteComponent implements OnInit, OnDestroy {

  contratMenuConvive: ContratMenuConviveDTO;
  subscriptionRoute: Subscription;

  form: FormGroup;
  formInfoGenerales: FormGroup = new FormGroup({});
  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];

  pathFile: string = HELP_FOLDERS.CONTRATS_MENUS_CONVIVES + '/contrats-menus-convives-edition';

  constructor(public utils: UtilsService,
              public gds: GenericDatagridService,
              private route: ActivatedRoute,
              private contratsMenusConvivesSvc: ContratsMenusConvivesService,
              private routeMapSvc: RoutemapService,
              private gfs: GenericFormService,
              private toastSvc: ToastService) {}

  ngOnInit() {
    this.iniDataRoute();
    this.initForm(this.contratMenuConvive);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  initForm = (contratMenuConvive: ContratMenuConviveDTO) => {
    this.fieldsInfosGenerales = this.contratsMenusConvivesSvc.getFields(contratMenuConvive);
    // desactiver les placeholder
    this.fieldsInfosGenerales.map(field => field.displayPlaceholder = false);
    this.formInfoGenerales = this.gfs.toFormGroup(this.fieldsInfosGenerales);
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });
  };

  iniDataRoute = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { contratMenuConviveSupplier: ContratMenuConviveSupplier }) => {
        this.contratMenuConvive = data.contratMenuConviveSupplier.contratMenuConvive;
        this.initForm(this.contratMenuConvive);
      });
  };

  saveFicheIdentite = () => {
    // Afficher les erreurs de validation
    this.gfs.validateAllFormFields(this.form);
    // On enregsitre que si le formulaire est valide
    if (this.form.valid) {
      this.contratMenuConvive = this.gfs.mapFormToDTO(this.form) as ContratMenuConviveDTO;
      // prepare declinaisons autorisées
      const declinaisons: DeclinaisonDTO[] = this.formInfoGenerales.controls['menuConviveDeclinaisonList'].value ? this.formInfoGenerales.controls['menuConviveDeclinaisonList'].value : [];
      // prepare regimes : regimes autorisés sur la prestation et régime référent
      const contraintes: ContrainteModel[] = this.formInfoGenerales.controls['regimes'].value;
      const contrainteReferente: ContrainteModel = this.formInfoGenerales.controls['regimeReferent'].value;

      const data: any = {
        id: this.contratMenuConvive?.id,
        idTypePrestation: this.contratMenuConvive?.typePrestationDTO.id,
        code: this.contratMenuConvive?.code,
        label: this.contratMenuConvive?.libelle,
        regimeReferentId: contrainteReferente.regime?.id,
        offreAlimentaireId: this.contratMenuConvive?.contratMenuId,
        offreAlimentaireSiteId: this.contratMenuConvive?.site.id,
        prestationId: this.contratMenuConvive?.convive?.id,
        actif: this.contratMenuConvive?.actif,
        regimesIds: contraintes.map(c => c.regime?.id),
        declinaisonsAutoriseesIds: declinaisons.map(d => d.id),
        declinaisonReferenceId: this.contratMenuConvive?.declinaisonReference?.id
      }

      this.contratsMenusConvivesSvc.saveFicheIdentite(data).subscribe(response => {
        const res: any = response.one;
        let warningsPlc: string = '';
        let warningsMenus: string = '';
        let warning: string = 'Certaines modifications ne sont pas prises en compte car ';

        if (res.dietsUsedByWorkforcesPlc && res.dietsUsedByWorkforcesPlc.length) warningsPlc += `des effectifs rattachés à des points de livraison client utilisent les régimes ${res.dietsUsedByWorkforcesPlc}`;
        if (res.dietsUsedByMenus && res.dietsUsedByMenus.length) warningsMenus += `des menus utilisent les régimes ${res.dietsUsedByMenus}`;

        if (warningsPlc.length) warning += warningsPlc;
        if (warningsPlc.length && warningsMenus.length) warning += ' et ';
        if (warningsMenus.length) warning += warningsMenus;

        if (warningsPlc.length || warningsMenus.length) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, warning);
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Enregistrement de la prestation ${this.contratMenuConvive.libelle.toUpperCase()} effectuée avec succès`);
        }
        this.contratsMenusConvivesSvc.announceSavedDTO(res);
        this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GCO_CMC, this.contratMenuConvive.contratMenuId, res.id, 'ficheidentite']);
      });
    }
  };

  openDeleteDialog = async (contratMenuConvive: ContratMenuConviveDTO) => {
    const data = new DialogDeleteSupplier();
    data.title = DIALOG.TITLE.SUPPRESSION;
    data.question = `${START_DELETEQUESTION} ${contratMenuConvive.libelle} ?`;
    data.idToDelete = contratMenuConvive.id;

    const result = confirm(`Etes vous sûr de vouloir supprimer la prestation "${contratMenuConvive.libelle}" ?`, 'Suppression de prestation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.contratsMenusConvivesSvc.delete(contratMenuConvive.id).subscribe((response: ResponseWrapper<number>) => {

        if (response.inError) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, response.resultMessage);
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Suppression réussie avec succès`);
          this.utils.sidenav = false;
          this.contratsMenusConvivesSvc.announceDeletedDTO(response);
        }

      });
    }
  };

  help = (): DialogMsgSupplier => {

    const dms = new DialogMsgSupplier();
    dms.title = `Paramétrage d'une prestation dans un contrat`;
    dms.logo = 'fa fa-question-circle  yoni-color';


    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      `Un contrat peut avoir plusieurs prestations.`,
      `Pour chaque prestation dans le contrat, on affecte  `,
    ];


    const sp1 = new Paragraphe();
    sp1.title = ``;
    sp1.lines = [
      'LE PLANNING',
      'LES EQUIPES',
      'LES POINTS DE LIVRAISON'
    ];
    p1.sousParagraphes = [sp1];

    const p2: Paragraphe = new Paragraphe();
    p2.title = `Régimes`;
    p2.lines = [
      `Une prestation peut avoir plusieurs régimes.`,
      `Le régime référent est le régime qui sera utilisé dans les menus pour pré-renseigner les autres régimes.  `,
    ];

    dms.content = {
      intro: ``,
      paragraphes: [p1, p2]

    };


    return dms;
  };

  /**
   * Pour pouvoir détruire un {@link ContratMenuConviveDTO}, il faut :
   * <li>avoir un droit d'écriture dessus.
   * <li>que son ID soit non nul.
   * @param contratMenuConvive
   */
  canDelete = (contratMenuConvive: ContratMenuConviveDTO): boolean => {
    const response: boolean = this.gds.canDelete(contratMenuConvive);
    return response;
  };
}

<dx-popup
  [showTitle]="true"
  title="Erreurs"
  [(visible)]="displayErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  width="700px"
  (close)="closeErrors()"
>
  <ng-container *ngIf="errors?.plcsWithoutRulesConfiguration?.length">
    <label>Liste des points de livraison client pour lesquels aucune règle de regroupement n'a été créée : </label>
    <dx-data-grid
      [dataSource]="errors?.plcsWithoutRulesConfiguration"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Point de livraison client" cellTemplate="templatePlc" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} point(s) de livraison client">
      </dxo-pager>
      <div *dxTemplate="let cell of 'templatePlc'">
        <a (click)="goToPlcPage(cell.row.data.idClient, cell.row.data.id)"><span class="cursor save-color">{{ cell.row.data.libelle }}</span></a>
      </div>
    </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="errors?.implementationsModelesPlatsNotRealized?.length">
    <label>Veuillez contrôler l'existence de l'implémentation d'un modèle de conditionnement plat pour les produits suivants : </label>
    <dx-data-grid
      [dataSource]="errors?.implementationsModelesPlatsNotRealized"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Produit" cellTemplate="templateProduit" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} produit(s)">
      </dxo-pager>
      <div *dxTemplate="let cell of 'templateProduit'">
        <a (click)="goToProductPage(cell.row.data.id)"><span class="cursor save-color">{{ cell.row.data.libelle }}</span></a>
      </div>
    </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="errors?.contraintesPlcsNotFound?.length">
    <label>Les prestations suivantes ne sont pas affectées à un modèle de PLC : </label>
    <dx-data-grid
      [dataSource]="errors?.contraintesPlcsNotFound"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Modèle plat / Point de livraison client" cellTemplate="valueToDisplay" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <div *dxTemplate="let cell of 'valueToDisplay'">
        {{ cell.row.data }}
      </div>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} information(s)">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="plcsNotTakenAccount?.length">
    <label>
      Certains points de livraison présent dans le plan de production ne sont pas pris en compte par le calcul du conditionnement car
      ces derniers sont absents des modèles points de livraison client :
    </label>
    <dx-data-grid
      [dataSource]="plcsNotTakenAccount"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Libellé" dataField="libelle" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} point(s) de livraison client">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
</dx-popup>

<dx-popup
  [showTitle]="true"
  title="Impression du bon d'allotissement"
  [(visible)]="displayDialog"
  [width]="400"
  [height]="'auto'"
  [maxHeight]="800"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <div class="print-order">
      Ordre d'impression

    <dx-radio-group [(value)]="defaultOrder" valueExpr="value" displayExpr="label" [items]="formatPrintingOptions"></dx-radio-group>
  </div>

  <div class="custom-button-container-right">
    <yo-button label="Imprimer" iconClass="fas fa-print"
               (onClick)="downloadBonAllotissement()">
    </yo-button>
    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (click)="closeDialog()">
    </yo-button>
  </div>

</dx-popup>

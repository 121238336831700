<dx-popup
  title="Impression d'une édition pour conditionnement"
  [showTitle]="true"
  [height]="'auto'"
  [width]="'700'"
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="x actif-container">
    <div class="label" title="Ordre">Avec détails complets ? <span class="danger-color">*</span></div>
    <div class="value">
      <dx-check-box [(value)]="printDetails"></dx-check-box>
    </div>
  </div>
  <div class="dialog-line actif-container">
    <div class="label" title="Ordre">Format d'impression <span class="danger-color">*</span></div>
    <div class="value">
      <dx-radio-group [(value)]="formatPrinting" valueExpr="value" displayExpr="label" [items]="formatPrintingOptions"></dx-radio-group>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <yo-button
      type="submit"
      iconClass="fas fa-print"
      buttonClass="cta-success"
      (onClick)="print()"
      label="Imprimer">
    </yo-button>
    <yo-button
      class="mg-l-5"
      buttonClass="cta-delete"
      (onClick)="closeDialog()"
      iconClass="fas fa-times"
      tooltip="Fermer">
    </yo-button>
  </div>

</dx-popup>

// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10689(79f96ad4fd)-C07/10/2024-19:03:17-B07/10/2024-19:08:13' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10689(79f96ad4fd)-C07/10/2024-19:03:17-B07/10/2024-19:08:13",
  branch: "master",
  latestTag: "6.1",
  revCount: "10689",
  shortHash: "79f96ad4fd",
  longHash: "79f96ad4fda530596ac13077e065b475bcf3ac1d",
  dateCommit: "07/10/2024-19:03:17",
  dateBuild: "07/10/2024-19:08:13",
  buildType: "Ansible",
  } ;

import {Component, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {AvoirFournisseurDto} from "../../../../core/dtos/facturation/avoirs/avoir-fournisseur-dto";
import {AvoirService} from "../../../../core/services/entities/avoir.service";
import {AvoirFournisseurLigneDto} from "../../../../core/dtos/facturation/avoirs/avoir-fournisseur-ligne-dto";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {PrixDto} from "../../../../core/dtos/prix-dto";
import {QuantiteDto} from "../../../../core/dtos/quantite-dto";
import {NavigationService} from "../../../../core/services/entities/navigation.service";
import {DxDataGridComponent} from "devextreme-angular";
import {AvoirStatusDto} from "../../../../core/dtos/facturation/avoirs/avoir-status-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ImageBase64DTO} from "../../../../core/dtos/images/ImageBase64DTO";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-avoirFournisseurDetailsDialog',
  templateUrl: './avoir-fournisseur-details-dialog.component.html',
  styleUrls: ['./avoir-fournisseur-details-dialog.component.scss']
})
export class AvoirFournisseurDetailsDialogComponent implements OnInit {

  @ViewChild("grid") grid: DxDataGridComponent;

  private subOpenDialog: Subscription
  private subAvoirStatus: Subscription

  public avoir: AvoirFournisseurDto = new AvoirFournisseurDto();

  public avoirStatus: AvoirStatusDto[] = [];

  public displayDialog: boolean = false;

  isImagePopupOpened: boolean = false;

  selectedLine: AvoirFournisseurLigneDto;

  constructor(private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              public avoirSvc: AvoirService,
              public utilsSvc: UtilsService,
              private i8nSvc: InternationalizationService,
              private navigationSvc: NavigationService) {
  }

  ngOnInit() {
    this.openDialogEditionSubscription();
    this.initAvoirsStatus();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.avoirSvc.openDetailsDialog$
      .subscribe((avoir: AvoirFournisseurDto) => {
        if (avoir) {
          this.displayDialog = true;
          this.avoir = {...avoir};
        }
      });
  };

  private initAvoirsStatus = () => {
    this.subAvoirStatus = this.avoirSvc.getAllStatus().subscribe(result => {
      this.avoirStatus = result;
      this.avoirStatus.forEach(avoir => avoir.displayCode = this.getStatusDisplayValue(avoir.code));
    });
  }

  private updateStatus = () => {
    /*if (this.avoirStatus && this.avoir)
      this.currentStatus = this.avoirStatus.find(status => status.code == this.avoir.statut);*/
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  getMotifsNonConformiteDisplayValue(avoirLigne: AvoirFournisseurLigneDto) {
    return avoirLigne.motifNonConformite.map(motif => motif.libelle).reduce((motif1, motif2) => motif1 + ", " + motif2).toString();
  }

  // ATTENTION ! Laisser cette fonction sour le format nomDeFonction() {...} sinon cela ne fonctionne plus !
  getTwoDigitsPrixDisplayValue(data: PrixDto | AvoirFournisseurLigneDto): string {
    if (!data)
      return null;
    if (data.hasOwnProperty("value"))
      return (data as PrixDto).value.toFixed(2) + " " + (data as PrixDto).uniteMonetaire;

    const prix = data[(this as any).dataField];
    return prix.value.toFixed(2) + " " + prix.uniteMonetaire;
  }

  // ATTENTION ! Laisser cette fonction sour le format nomDeFonction() {...} sinon cela ne fonctionne plus !
  getFourDigitsPrixDisplayValue(data: PrixDto | AvoirFournisseurLigneDto): string {
    if (!data)
      return null;
    if (data.hasOwnProperty("value"))
      return (data as PrixDto).value.toFixed(3) + " " + (data as PrixDto).uniteMonetaire;

    const prix = data[(this as any).dataField];
    return prix.value.toFixed(3) + " " + prix.uniteMonetaire;
  }

  // ATTENTION ! Laisser cette fonction sour le format nomDeFonction() {...} sinon cela ne fonctionne plus !
  getQuantiteDisplayValue(data: QuantiteDto | AvoirFournisseurLigneDto): string {
    if (!data)
      return null;

    if (data instanceof QuantiteDto)
      return +data.value.toFixed(3) + " " + data.uniteDeMesure;

    let quantite = data[(this as any).dataField];
    return +quantite.value.toFixed(3) + " " + quantite.uniteDeMesure.abreviation;
  }

  getStatusDisplayValue = (code: string): string => {
    if (!code)
      return null;
    let result = this.i8nSvc.getLabelFromCode("AVOIR_STATUS_" + code, null);
    if (!result)
      return this.i8nSvc.getLabelFromCode("AVOIR_STATUS_UNKNOWN", null);
    return result;
  };

  navigateToFacture() {
    this.navigationSvc.navigateToFacture(this.avoir.facture.fournisseur.id, this.avoir.facture.numero);
  }

  resizeChilds($event) {
    let data = document.getElementById('data');

    if ($event.hasOwnProperty('height'))
      this.grid.height = $event.height - data.offsetHeight - 92;
    else {
      let body: HTMLDivElement = data.closest('.dx-popup-content');
      this.grid.height = body.offsetHeight - data.offsetHeight - 132;
    }
  }

  printAvoirPDF = () => {
    this.avoirSvc.print(this.avoir.id);
  };

  editAvoir() {
    this.avoirSvc.edit(this.avoir).subscribe(result => {
      if (result) {
        this.avoir = result.one;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode("SAVE_SUCCESS", null));
        this.avoirSvc.announceAvoirUpdated(this.avoir);

      }
    });
  }

  onStatusChange = ($event: any) => {
    this.avoir.statut = $event.value;
  };

  onReferenceChange = ($event: any) => {
    this.avoir.reference = $event.value;
  };

  onMontantValideChange = ($event: any) => {
    this.avoir.montantValide.value = $event.value;
  };

  onCommentaireChange($event: any) {
    this.avoir.commentaire = $event.value;
  }

  isAtLeastOnImage = () => {
    return this.avoir?.lignes?.some(ligne => this.isImageButtonAvailable(ligne));
  };

  getLotArticleToDisplay = (avoirLigne: AvoirFournisseurLigneDto) => {
    return avoirLigne?.lotArticle?.lotArticleFournisseur ? avoirLigne?.lotArticle?.lotArticleFournisseur : avoirLigne?.lotArticle?.lotArticle;
  };

  onImagePopupClose(images: ImageBase64DTO[]) {
    this.isImagePopupOpened = false;
    this.selectedLine = null;
  }

  isImageButtonAvailable(avoirLigne: AvoirFournisseurLigneDto) {
    return avoirLigne?.lotArticle?.images?.length > 0;
  }

  openImageDialog(avoirLigne: AvoirFournisseurLigneDto) {
    this.selectedLine = avoirLigne;
    this.isImagePopupOpened = true;
  }

  getImageCount(avoirLigne: AvoirFournisseurLigneDto) {
    if (avoirLigne?.lotArticle?.images?.length > 0)
      return "x " + avoirLigne.lotArticle.images.length;
    return null;
  }

  formatDate = (avoirLigne: AvoirFournisseurLigneDto) => {
    if (avoirLigne && avoirLigne?.dlc)
      if (typeof avoirLigne.dlc == "string")
        return this.utilsSvc.formatDate(avoirLigne.dlc, "DD/MM/yyyy HH:mm:SS", "DD/MM/yyyy");
      else
        return this.utilsSvc.formatToDDMMYYYY(avoirLigne.dlc.getTime());
    return null;
  }

  sendMail = async () => {
    if (await confirm("Confirmez-vous l'envoi de la demande d'avoir au fournisseur ?", "Envoi de mail"))
      this.avoirSvc.sendMail(this.avoir.id).subscribe(result => {
          if (result)
            this.avoir.emailHisto = result;
        }
      );
  }
}

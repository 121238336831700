import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs/index";
import {ActivatedRoute} from "@angular/router";
import {PreparationConditionnementSupplier} from "../../preparation-conditionnement-resolver.service";
import {PreparationConditionnementService} from "../../../core/services/conditionnements/preparation-conditionnement.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";
import {HELP_FOLDERS, UI_COLORS} from "../../../core/constants";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {RoutemapService} from "../../../core/services/routemap.service";
import {PrintMenuService} from "../../../core/services/gestionmenus/print-menu.service";

@Component({
  selector: 'yo-preparation-calcul-conditionnement',
  templateUrl: './preparation-calcul-conditionnement.component.html',
  styleUrls: ['./preparation-calcul-conditionnement.component.scss']
})
export class PreparationCalculConditionnementComponent implements OnInit, OnDestroy {

  displayBonLivraisonErrors: boolean;
  displayErrors: boolean;

  subscriptionRoute: Subscription;
  idPlanProduction: number;

  headersResults: any[] = [];
  errors: any = {};
  NoDataText: string;

  idParentSelected: number;

  plcsNotTakenAccount: PointDeLivraisonDTO[] = [];

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-calcul-global';
  headerPoidsNetTotalByFicheTechnique: any;
  headerQteConditionneeByFicheTechnique: any;
  headerNbRestesByFicheTechnique: any;

  detailsByPoidsConditionnement: any;

  detailsByPoidsConditionnementForParentSelected: any;

  private columnPrestation: string = 'prestationLabel';

  constructor(private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private pcSvc: PreparationConditionnementService,
              public utils: UtilsService,
              public printMenuSvc: PrintMenuService) {
  }

  ngOnInit(): void {
    this.routeSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { preparationConditionnementSupplier: PreparationConditionnementSupplier }) => {
        if (data.preparationConditionnementSupplier) {
          this.idPlanProduction = data.preparationConditionnementSupplier.idPlanProduction;
          this.startCalculation(data.preparationConditionnementSupplier.isRefresh);
        }
      });
  };

  goToProductPage = (idProduct: number) => window.open(`/gestionproduits(secondary:gestionproduits/produit/true/${idProduct}/conditionnement)`, '_blank');

  goToPlcPage = (idClient: number, idPlc: number) => window.open(`/gestion-clients(secondary:point-de-livraison/${idClient}/${idPlc}/conditionnement/regles-regroupements)`, '_blank');

  closeErrors = () => {
    this.displayErrors = false;
  }

  startCalculation = (refresh?: boolean) => {
    this.NoDataText = 'En cours de chargement...';
    this.pcSvc.startCalculation(this.idPlanProduction, refresh)
      .subscribe(data => {
        if (data.one?.contraintesPlcsNotFound || data.one?.implementationsModelesPlatsNotRealized || data.one?.plcsWithoutRulesConfiguration) {
          this.errors.contraintesPlcsNotFound = data.one.contraintesPlcsNotFound || [];
          this.errors.implementationsModelesPlatsNotRealized = data.one.implementationsModelesPlatsNotRealized ? data.one.implementationsModelesPlatsNotRealized.map(val => {
            let tmp: string[] = val.split(",");
            return {id: parseInt(tmp[0]), libelle: tmp[1]}
          }) : [];
          this.errors.plcsWithoutRulesConfiguration = data.one.plcsWithoutRulesConfiguration ? data.one.plcsWithoutRulesConfiguration.map(val => {
            let tmp: string[] = val.split(",");
            return {id: parseInt(tmp[0]), idClient: parseInt(tmp[1]), libelle: tmp[2]}
          }) : [];
          this.displayErrors = true;
          this.NoDataText = 'Pas de données';
        } else {
          this.plcsNotTakenAccount = data.one?.plcsNotTakenAccount;
          this.displayErrors = this.plcsNotTakenAccount?.length > 0;
          this.pcSvc.getAllResults(this.idPlanProduction)
            .subscribe(data => {
                const res = data.one;
                this.headersResults = res.rows.length ? res.rows.filter(d => !d.distributionDirecte) : res.rows;
                this.headerPoidsNetTotalByFicheTechnique = res.headerPoidsNetTotalByFicheTechnique;
                this.headerQteConditionneeByFicheTechnique = res.headerQteConditionneeByFicheTechnique;
                this.headerNbRestesByFicheTechnique = res.headerNbRestesByFicheTechnique;
                this.detailsByPoidsConditionnement = res.detailsByPoidsConditionnement;
              },
              err => {
                this.NoDataText = 'Pas de données';
              }
            );
        }
      });
  };

  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      event.rowElement.style.cursor = 'pointer';
    }
  };

  onCellPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === this.columnPrestation && event.data?.regroupe) event.cellElement.style.backgroundColor = UI_COLORS.CHECKED;
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === this.columnPrestation && !event.data?.regroupe) event.cellElement.style.backgroundColor = UI_COLORS.NOT_CHECKED;
  };

  previousStep = () => {
    this.routeMapSvc.goToSecondaryRoute(['steps', 'plan-production', this.idPlanProduction]);
  }

  loadChildren = $event => {
    if ($event.rowType === 'data') {
      const id = $event.data.id;
      this.idParentSelected = id;
      this.detailsByPoidsConditionnementForParentSelected = this.detailsByPoidsConditionnement.filter(d => d.idParent === id || d.id === id);
    }
  };

  printEditionConditionnement = (): void => {
    this.pcSvc.announcePrintConditionnement();
  }

}
